/* Define root color variables */
:root {
  --white: #fff;
  --light-red: #ffcccc;
  --medium-red: #cc0000;
  --dark-red: #b30000;
  --darker-red: #800000;
  --soft-red: #e74c3c;
  --softer-red: #d9534f;
  --light-gray: #f2dede;
  --medium-dark-red: #c0392b;
  --gray: #f8f9fa;
  --dark-gray: #6a5858;
  --very-light-gray: #f9f9f9;
  --lightest-gray: rgb(234, 231, 231);
  --lighter-red: #f44336;
  --hover-lighter-red: #d32f2f;
  --logo-red: rgb(182, 34, 34);
  --background-gray: rgb(245, 244, 244);
}

/* General Centering */

.nodecoration {
  text-decoration: none !important;
}
.nodecoration_text {
  text-decoration: none !important;

  font-size: 23px !important;
  padding-top: 3px;
}

.textdecoration_title {
  text-decoration: none !important;
 
  font-size: 30px !important;
}

.textdecoration_title_pdfone {
  text-decoration: none !important;
  text-transform: uppercase !important;
}
.font_size_upload_button {
  font-size: 13px !important;
}
.text_decorantion_summary {
  font-size: 14px;
}
/* Navbar Styling */
.text_link {
  color: var(--white);
  text-decoration: none;
  font-size: 20px;
}
.navbarbackground {
  z-index: 1041;
  box-shadow: 0 4px 8px rgba(179, 0, 0, 0.2); /* Dark red shadow */
  background-color: var(--light-red) !important; /* Light red */
  padding: 20px;
}
.background_navbar {
  background-color: var(--softer-red);
  color: var(--white) !important;
  padding: 10px 30px;
  border-radius: 10px;
}
.active-link {
  color: rgb(192 57 43) !important;
}
.active-link-blue {
  color: #2b4dc0 !important;
}
.active-link-green {
  color: #2bba84 !important;
}
.active-link-orange {
  color: #ba6b2b !important;
}
.active-link-purple {
  color: #7a2bba !important;
}
.active-link-pink {
  color: #ba2bc0 !important;
}

.navbar-content {
  color: var(--dark-red) !important; /* Dark red */
  text-align: center !important;
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.navbar-actions {
  display: flex; /* Aligning icons */
  align-items: center;
}

/* Icon Styling */
.help-icon,
.history-icon {
  font-size: 24px;
  cursor: pointer;
  margin-right: 20px;
  color: var(--darker-red); /* Medium dark red */
  transition: color 0.3s; /* Added transition for hover effect */
}
.help-icon:hover,
.history-icon:hover {
  color: var(--dark-red); /* Dark red */
}

/* Help and History Containers */
.help-container,
.history-container {
  text-align: left;
  padding: 15px; /* Increased padding for better readability */
  background-color: var(--light-red); /* Very light red */
  border: 1px solid var(--medium-red); /* Medium red */
  border-radius: 5px;
  margin: 10px auto; /* Centered with margin auto */
  max-height: 200px;
  overflow-y: auto;
  width: 80%; /* Reduced width for better layout */
}

/* Social Icon Styling */
.social-icons {
  margin-right: 10px;
  width: 1.5em;
  height: 1.5em;
  color: var(--light-gray); /* Light red */
  transition: color 0.3s;
}
.social-icons:hover {
  color: var(--medium-dark-red); /* Soft red */
}

/* Image Upload Styling */
.uploadCard {
  width: 80%;
  display: inline-block;
}
.image {
  display: block;
  max-width: 100%;
  height: auto;
  box-shadow: 0 2px 4px rgba(153, 0, 0, 0.1); /* Light shadow */
  border: 1px solid var(--light-gray); /* Light red */
  margin: 10px auto; /* Added margin for spacing */
}

/* Button Wrapper Styling */
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-top: 10px; /* Added margin for spacing */
}
.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/* Button Styling */
.btn {
  border: none;
  color: var(--white);
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 18px; /* Reduced font size for better appearance */
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin: 5px; /* Added margin for button spacing */
  background-color: var(--soft-red); /* Soft red */
}
.btn:hover {
  background-color: var(--medium-dark-red); /* Darker red */
  transform: scale(1.05);
}
.btn.download-btn {
  background-color: var(--dark-red); /* Dark red */
  transition: background-color 0.3s;
}
.btn.download-btn:hover {
  background-color: var(--darker-red); /* Medium dark red */
}
.btn-reset {
  background-color: var(--softer-red); /* Soft red */
}
.btn-reset:hover {
  background-color: #c12e2a; /* Darker soft red */
}

/* Slider Styling */
#qualitySlider {
  width: 100%;
  padding: 0;
  margin: 10px 0;
}

/* Compressed Image Message */
.compressed-message {
  font-size: 20px; /* Reduced font size for better layout */
  font-weight: bold;
  color: var(--dark-red); /* Dark red */
  margin-top: 10px;
  transition: color 0.3s;
}
.compressed-message:hover {
  color: var(--darker-red); /* Medium dark red */
}

/* Button Container Styling */
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.nav {
  padding: 0.5rem 1rem;
  background: var(--white);
  border-bottom: 1px solid var(--background-gray);
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Kanit", sans-serif;
}

.logo {
  padding-left: 85px;
  font-size: 3rem;
  color: var(--logo-red);
}

.pdf-btn,
.upload-btn {
  border: none;
  outline: none;
  background: #4f4c5e;
  color: var(--white);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-item {
  margin: 1rem;
  position: relative;
}

.delete-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: var(--lighter-red);
  color: var(--white);
  border: none;
  border-radius: 50%;
  padding: 0.3rem;
  cursor: pointer;
  transition: background 0.3s;
}

.delete-btn:hover {
  background: var(--hover-lighter-red);
}

/* Drop box styling */
.drop-box {
  border: 2px dashed var(--dark-gray);
  border-radius: 5px;
  padding: 10rem;
  text-align: center;
  cursor: pointer;
  margin-top: 2rem;
  margin-left: 170px;
  margin-right: 200px;
}

.drop-box:hover {
  background-color: var(--very-light-gray);
}

.drop-text {
  font-size: 1rem;
  color: #888;
}

.drop-text:hover {
  color: #555;
}

.pdf {
  margin-left: 80px;
}

.border_red {
  border: 1px solid var(--soft-red) !important;
  border-radius: 10px;
}

.block_css_home {
  background-color: var(--softer-red);
  padding: 10px 30px;
  text-align: center;
  border-radius: 20px;
}
.margin_bottom_header {
  margin-bottom: 60px;
  margin-top: 20px;
}
.bggray {
  background-color: rgb(251, 248, 248);
  height: 45px;
}

.margin_top_bottom_abd {
  margin: 50px 0px;
}

.margin_bottomnavber {
  margin-bottom: 40px;
  border-bottom: 1px solid var(--dark-red) !important; /* Bottom border only */
  background-color: white !important;
}
/* Custom Styles for Buttons and Links */
.custom-btn {
  background-color: var(--white) !important; /* Default background */
  color: var(--softer-red) !important; /* Text color */
  border: 2px solid var(--softer-red); /* Border color */
}

.fontsize_links {
  font-size: 17px !important;
  font-weight: 400;
  text-decoration: none !important;
}

.border_comppress_title {
  border-radius: 20px;
}
.red_back_icoins_compresser {
  color: var(--dark-red) !important;
}
.box_uplod {
  border-radius: 20px !important;
  padding: 50px !important;
  color: var(--dark-red) !important;
}
.button_desoing {
  background-color: rgb(192 57 43) !important;
  color: white !important;
  padding: 8px 10px !important;
  border-radius: 10px !important;
  font-size: 17px !important;
  border: 1px solid !important;
}
.button_desoing_pink {
  background-color:  #ba2bc0 !important;
  color: white !important;
  padding: 8px 10px !important;
  border-radius: 10px !important;
  font-size: 17px !important;
  border: 1px solid !important;
}
.border_line {
  padding-bottom: 7px !important;
  border-bottom: 3px solid !important; /* Apply border only to the bottom */
  font-weight: 500 !important;
}

.button_desoing_orange {
  background-color:  #ba6b2b !important;
  color: white !important;
  padding: 8px 10px !important;
  border-radius: 10px !important;
  font-size: 17px !important;
  border: 1px solid !important;
}
.button_desoing_blue {
  background-color: #2b4dc0 !important;
  color: white !important;
  padding: 8px 10px !important;
  border-radius: 10px !important;
  font-size: 17px !important;
  border: 1px solid !important;
}

.button_desoing_purple {
  background-color: #7a2bba !important;

  color: white !important;
  padding: 8px 10px !important;
  border-radius: 10px !important;
  font-size: 17px !important;
  border: 1px solid !important;
}

.compressbuttons_desgin {
  background-color: var(--white) !important;
  color: var(--medium-dark-red) !important;
  padding: 8px 10px !important;
  border-radius: 10px !important;
  font-size: 17px !important;
  border: 1px solid !important;
}
.compressbuttons_desgin_slider_button {
  background-color: #fdecf9 !important;
  border:  1px solid #ddc3dd !important;
  color: #ba2bc0 !important;
  padding: 8px 10px !important;
  border-radius: 10px !important;
  font-size: 17px !important;
  border: 2px solid !important;
}
.compressbuttons_desgin_pdf {
  background-color: #f2e9fc !important;
  border: 2px solid #cdc2d6 !important;
  color: #7a2bba !important;
  padding: 7px 16px !important;
  border-radius: 10px !important;
  font-size: 13px !important;
  border: 1px solid !important;
}
.compressbuttons_desgin_png {
  background-color: #f9ece3 !important;
  border: 2px solid #ded0c4 !important;
  color: #ba6b2b !important;
  padding: 7px 16px !important;
  border-radius: 10px !important;
  font-size: 13px !important;
  border: 1px solid !important;
}
.sidebar-item.active {
  background-color:  #ba2bc0 !important;
  color: white !important;
  padding: 8px 10px !important;
  border-radius: 10px !important;
  font-size: 17px !important;
  border: 1px solid !important;

}

.text_red_primary {
  color: var(--dark-red) !important;
}

/* Change the track color */
.slider::-webkit-slider-runnable-track {
  background: red;
}

.slider::-ms-track {
  background: transparent;
  border-color: transparent !important;
  color: var(--dark-red) !important;
}

.slider::-ms-fill-lower {
  background: red !important;
}

.slider::-ms-fill-upper {
  background: red !important;
}

/* Change the thumb color */
.slider::-webkit-slider-thumb {
  background-color: red !important;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  background-color: red !important;
  border-radius: 50%;
}

.slider::-ms-thumb {
  background-color: red !important;
  border-radius: 50%;
}

/* Custom focus color when interacting with the slider */
.slider:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.4);
}

.slider:focus::-moz-range-thumb {
  box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.4);
}

.slider:focus::-ms-thumb {
  box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.4);
}

.withd_hieght {
  width: 300px;
  height: 300px;
}

.color_home_section {
  color: var(--medium-dark-red) !important;
}

.box_shadowthis {
  background-color: red !important;
}
.tools__item__icon_css {
  background-color: white !important;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.red_color_class_home {
  background-color: #ffecec !important;
  border: none !important;

}
.red_color_text_class {
  color: rgb(192 57 43) !important;
}

.blue_color_class_home {
  background-color: #eaf2f9 !important;
  border: none !important;

}
.blue_color_text_class {
  color: #2b4dc0 !important;
}

.pink_color_class_home {
  background-color: #fdecf9 !important;
  border: none !important;
  
}
.pink_color_text_class {
  color: #ba2bc0 !important;
}
.green_color_class_home {
  background-color: #f0fbfe !important;
  border: none !important;

}
.green_color_text_class {
  color: #2bba84 !important;
}

.orange_color_class_home {
  background-color: #f9ece3 !important;
  border: none !important;
 
}
.orange_color_text_class {
  color: #ba6b2b !important;
  
}

.purple_color_class_home {
  background-color: #f2e9fc !important;
  border: none !important;
}
.purple_color_text_class {
  color: #7a2bba !important;
}
/* Add smooth transition to the card */
.card_hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.circle-background_icons {
  padding: 9px !important;
  border-radius: 50% !important;
  font-size: 14px !important;
}
/* Hover effect for the card */
.card_hover:hover {
  transform: scale(1.05); /* Slightly enlarges the card */
  box-shadow: 0 3px 4px rgba(154, 153, 153, 0.2); /* Adds shadow effect */
}

/* Base styles for the card */
.card_hover {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.margin_bottom_choose_size {
  margin-bottom: 50px !important;
}
/* Hover effect for the card */

/* Circle effect */
.circle {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.3); /* Default color */
  border-radius: 50%;
  pointer-events: none;
  cursor: none;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.2s ease;
  transform: translate(-50%, -50%);
  z-index: 0;
}
/* Add this CSS to your styles */
.hide-cursor {
  cursor: none;
}

/* Ensuring the card content stays above the circle */
.card_hover .card-body {
  position: relative;
  z-index: 1;
}

/* Animation for falling icons */

/* General style for the icons */
/* Falling animation */
@keyframes fall {
  0% {
    transform: translateY(-100%); /* Start above the visible area */
    opacity: 0; /* Fully transparent at the start */
  }
  10% {
    opacity: 1; /* Fade in after starting */
  }
  100% {
    transform: translateY(100vh); /* End below the visible area */
    opacity: 0; /* Fade out when reaching the bottom */
  }
}

/* Icon styles */
.falling-icon {
  position: absolute;
  font-size: 2.5rem;
  animation: fall 8s linear infinite; /* Adjusted duration for a slower fall */
  opacity: 0.6;
  color: rgba(0, 0, 0, 0.7);
  opacity: 0; /* Start invisible */
}

.falling-icon:nth-child(1) {
  left: 13%;
  animation-delay: 0s; /* Falls immediately */
  animation-duration: 8s; /* Falls over 8 seconds */
}

.falling-icon:nth-child(2) {
  left: 29%;
  animation-delay: 4s !important; /* Starts falling after 10 seconds */
  animation-duration: 6s !important; /* Takes 9 seconds to fall */
}

.falling-icon:nth-child(3) {
  left: 45%;
  animation-delay: 3s !important; /* Starts falling after 20 seconds */
  animation-duration: 6s !important; /* Falls over 10 seconds */
}

.falling-icon:nth-child(4) {
  left: 61%;
  animation-delay: 5s !important; /* Starts falling after 35 seconds */
  animation-duration: 9s !important; /* Takes longer, 12 seconds to fall */
}

.falling-icon:nth-child(5) {
  left: 77%;
  animation-delay: 1s !important; /* Starts falling after 50 seconds */
  animation-duration: 6s !important; /* Falls quicker, over 8 seconds */
}

.falling-icon:nth-child(6) {
  left: 90%;
  animation-delay: 3s !important; /* Starts falling after 65 seconds */
  animation-duration: 8s !important; /* Falls over 11 seconds */
}

.purple_color_text_class_icon_falling {
  color: rgb(226, 207, 226) !important; /* Soft purple */
}

.green_color_text_class_icon_falling {
  color: rgb(207, 226, 207) !important; /* Soft green */
}

.red_color_text_class_icon_falling {
  color: rgb(226, 207, 207) !important; /* Soft red */
}

.blue_color_text_class_icon_falling {
  color: rgb(207, 216, 226) !important; /* Soft blue */
}

.pink_color_text_class_icon_falling {
  color: rgb(226, 207, 216) !important; /* Soft pink */
}

.orange_color_text_class_icon_falling {
  color: rgb(226, 216, 207) !important; /* Soft orange */
}

.background_compresser {
  background-color: #ffecec !important;
  min-height: 120vh !important; /* 100% of the viewport height */
}

.background_compresser_blue {
  background-color: #eaf2f9 !important;
  min-height: 140vh !important; /* 100% of the viewport height */
}

.maring_top_row {
  padding-top: 40px !important;
}

.background_compresser_green {
  background-color: #f0fbfe !important;
  min-height: 120vh !important; /* 100% of the viewport height */
}

.background_compresser_pink {
  background-color: #fdecf9 !important;
  min-height: 155vh !important; /* 100% of the viewport height */
}

.background_compresser_purple {
  background-color: #f2e9fc !important;
  min-height: 120vh !important; /* 100% of the viewport height */
}

.background_compresser_orange {
  background-color: #f9ece3 !important;
  min-height: 120vh !important; /* 100% of the viewport height */
}

.card_width_hieght {
  width: 100% !important;
}
.width_of_input_pdf {
  width: 50% !important;
}

.borderofthenavbar {
  border-radius: 0px 0px 20px 20px !important;
}

.width_hieght_logo {
  width: 102px !important;

}

.widht_hieght_home_section_logo {
  width: 145px !important;
 
}
.padding_card_section_common {
  padding: 48px 24px !important;
}
.card_pdf_dflex {
  display: flex !important;

  align-items: center !important;
}
.ms_auto_button {
  margin-left: auto !important;
}

@media (max-width: 720px) {
  
  .padding_card_section_common  {
      padding: 38px 14px !important;
  }
  
.textdecoration_title {
  text-decoration: none !important;
 
  font-size: 25px !important;
}
.subtitle_text_compressor {
  margin: 14px 0px;
}
.card_pdf_dflex {
  display: flex !important;
  flex-direction: column;
  gap: 6px;
  justify-content: center !important;
  align-items: center !important;
}
.ms_auto_button {
  margin-left: 0px !important;
}
.width_hieght_col_homesection {
  width: 80% !important;
}
.col_centerthing_homesection {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
}

@media (min-width: 720px) and (max-width: 1100px) {
  .fontsize_links {
    font-size: 14px !important;
    font-weight: 400;
    text-decoration: none !important;
}
}
